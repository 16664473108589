import React from'react';
import { useTranslation } from 'react-i18next';
import '../styles/Terms.css';

const Terms = () => {

    const { t } = useTranslation();
  
    return (
        <div className="terms">
            <h2>{t('Terms and Conditions for app.spytik.com')}</h2>
            <div className='term_title'>{t('1. Acceptance of Terms')}</div>
            <div className='term_corps'>{t("By accessing and using app.spytik.com (the 'Site'), you agree to be bound by these Terms and Conditions ('Terms'). If you do not agree to these Terms, you should not use or access the Site.")}</div>
            <div className='term_title'>{t('2. Changes to Terms')}</div>
            <div className='term_corps'>{t('We reserve the right to modify these Terms at any time. Your continued use of the Site after such changes constitutes your acceptance of the new Terms.')}</div>
            <div className='term_title'>{t('3. Site Services')}</div>
            <div className='term_corps'>{t("app.spytik.com provides a service that aggregates popular TikTok videos ('Service'). These videos are sourced from TikTok and are not hosted on our Site.")}</div>
            <div className='term_title'>{t('4. User Conduct')}</div>
            <div className='term_corps'>{t("You agree to use the Site only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the Site.")}</div>
            <div className='term_title'>{t('5. Intellectual Property')}</div>
            <div className='term_corps'>{t('All content included on the Site, such as text, graphics, logos, button icons, and images, is the property of app.spytik.com or its content suppliers and protected by international copyright laws.')}</div>
            <div className='term_title'>{t('6. Third-Party Links')}</div>
            <div className='term_corps'>{t('The Site may contain links to third-party websites that are not owned or controlled by app.spytik.com. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites.')}</div>
            <div className='term_title'>{t('7. Disclaimer of Warranties')}</div>
            <div className='term_corps'>{t("The Site and its content are provided 'as is'. We make no representations or warranties of any kind, whether express or implied, as to the Site's operation or the information, content, or materials included on the Site.")}</div>
            <div className='term_title'>{t('8. Limitation of Liability')}</div>
            <div className='term_corps'>{t('app.spytik.com shall not be liable for any damages of any kind arising from the use of the Site, including, but not limited to direct, indirect, incidental, punitive, and consequential damages.')}</div>
            <div className='term_title'>{t('9. Governing Law')}</div>
            <div className='term_corps'>{t('These Terms shall be governed by the international laws. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the local courts.')}</div>
            <div className='term_title'>{t('10. Contact Information')}</div>
            <div className='term_corps'>{t('For any questions regarding these Terms, please contact us at contact@spytik.com.')}</div>
        </div>
    )
}

export default Terms;