import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/Footer.css';

const Footer = () => {

  const { t } = useTranslation();
  
  return (
    <div className="footer">
        <div className="footerLeft">{t('2023 Copyright © SpyTik')}</div>
        <div className='footerRight'>{t('All rights reserved')}</div>
    </div>
  );
};

export default Footer;
