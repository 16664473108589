import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import '../styles/Subscription.css';
import { AuthContext } from '../AuthProvider';

const Subscription = () => {

  const [loadingCheckout, setLoadingCheckout] = useState(false);
  
  const { rank } = useContext(AuthContext);

  const { t } = useTranslation();

  const backendUrl = process.env.REACT_APP_BACK_API_URL;

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  const handleCheckout = async (plan_id) => {
    setLoadingCheckout(true);
    const stripe = await stripePromise;
    const token = localStorage.getItem('token');
    const response = await fetch(`${backendUrl}/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ plan_id }),
    });
    const session = await response.json();
    if (response.ok) {
      stripe.redirectToCheckout({ sessionId: session.session_id });
      setLoadingCheckout(false);
    } else {
      console.error(session.error);
      setLoadingCheckout(false);
      alert("An error occured. Please try again later.");
    }
  };

  const renderButton = (planRank) => {
    if (rank === planRank) {
      return <button disabled>{t('current_plan')}</button>;
    } else {
      return <button onClick={() => handleCheckout(planRank)} disabled={loadingCheckout}>{t('choose_plan')}</button>;
    }
  };

  return (
    <div className="subscription">
      <div className='subscription-header'>
        <h2>{t('List of plans')}</h2>
        <h3><img src="/card.png" alt="" />{t('Cancel anytime')}</h3>
      </div>
      <div className="subscription-cards">
        <div className="subscription-card">
          <div className="subscription-card-header">
            {t('Plan Standart')}
          </div>
          <div className="subscription-card-price">
            $29.99/mo
          </div>
          <div className="subscription-card-description">
            <ul>
              <li><img src='/green_check.png' alt='' />{t('10 predefined niches')}</li>
              <li><img src='/green_check.png' alt='' />{t('5 accounts analyse (per month)')}</li>
              <li><img src='/green_check.png' alt='' />{t('Download 20 videos (per month)')}</li>
              <li><img src='/green_check.png' alt='' />{t('Top creators of the week')} (15)</li>
              <li><img src='/green_check.png' alt='' />{t('Top videos of the week')} (15)</li>
              <li><img src='/red_cross.png' alt='' />{t('Advanced filters')}</li>
              <li><img src='/red_cross.png' alt='' />{t('Add in favorites')}</li>
              <li><img src='/red_cross.png' alt='' />{t('Customized niche search')}</li>
              <li><img src='/red_cross.png' alt='' />{t('See the money generated')}</li>
              <li><img src='/red_cross.png' alt='' />{t('Personalised Coaching')}</li>
            </ul>
          </div>
          <div className='subscription-card-button'>
            {renderButton(3)}
          </div>
        </div>
        <div className="subscription-card">
          <div className="subscription-card-header">
            {t('Plan Advanced')}
          </div>
          <div className="subscription-card-price">
            $49.99/mo
          </div>
          <div className="subscription-card-description">
            <ul>
              <li><img src='/green_check.png' alt='' />{t('20 predefined niches')}</li>
              <li><img src='/green_check.png' alt='' />{t('20 accounts analyse (per month)')}</li>
              <li><img src='/green_check.png' alt='' />{t('Download 50 videos (per month)')}</li>
              <li><img src='/green_check.png' alt='' />{t('Top creators of the week')} (35)</li>
              <li><img src='/green_check.png' alt='' />{t('Top videos of the week')} (35)</li>
              <li><img src='/green_check.png' alt='' />{t('Advanced filters')}</li>
              <li><img src='/green_check.png' alt='' />{t('Add in favorites')}</li>
              <li><img src='/red_cross.png' alt='' />{t('Customized niche search')}</li>
              <li><img src='/red_cross.png' alt='' />{t('See the money generated')}</li>
              <li><img src='/red_cross.png' alt='' />{t('Personalised Coaching')}</li>
            </ul>
          </div>
          <div className='subscription-card-button'>
            {renderButton(5)}
          </div>
        </div>
        <div className="subscription-card">
          <div className="subscription-card-header">
            {t('Plan Expert')}
          </div>
          <div className="subscription-card-price">
            $69.99/mo
          </div>
          <div className="subscription-card-description">
            <ul>
              <li><img src='/green_check.png' alt='' />{t('50 predefined niches')}</li>
              <li><img src='/green_check.png' alt='' />{t('40 accounts analyse (per month)')}</li>
              <li><img src='/green_check.png' alt='' />{t('Download 100 videos (per month)')}</li>
              <li><img src='/green_check.png' alt='' />{t('Top creators of the week')} (60)</li>
              <li><img src='/green_check.png' alt='' />{t('Top videos of the week')} (60)</li>
              <li><img src='/green_check.png' alt='' />{t('Advanced filters')}</li>
              <li><img src='/green_check.png' alt='' />{t('Add in favorites')}</li>
              <li><img src='/green_check.png' alt='' />{t('Customized niche search')}</li>
              <li><img src='/green_check.png' alt='' />{t('See the money generated')}</li>
              <li><img src='/green_check.png' alt='' />{t('Personalised Coaching')}</li>
            </ul>
          </div>
          <div className='subscription-card-button'>
            {renderButton(10)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
