import React from 'react';
import { useTranslation } from 'react-i18next';

const HeaderMobile = ({changeLanguage, setShowLanguageMenu, handleLoginLogout, showLanguageMenu, currentLanguage, languageMenuRef}) => {

  const { t } = useTranslation();

  return (
    <div className="main_logo_mobile">
      <img className="frame" alt="" src="/logo.png" />
      <div className="title-wrapper">SpyTik</div>
      <div className='div_buttons'>
        {localStorage.getItem('token') ?
          <button className="loginButton" onClick={handleLoginLogout}>
              <img src="/login.svg" alt="logout" className="loginIcon"/>{t('Logout')}
          </button>
          :
          <button className="loginButton" onClick={handleLoginLogout}>
              <img src="/login.svg" alt="login" className="loginIcon"/>{t('Login')}
          </button>
        }
        <div className='flag_icon' onClick={() => setShowLanguageMenu(!showLanguageMenu)}>
          <img src={`/flag_${currentLanguage}.png`} alt={currentLanguage} />
        </div>
        {showLanguageMenu && (
          <div ref={languageMenuRef} className="languageMenu">
            <button onClick={(e) => changeLanguage('en', e)}><img src="/flag_en.png" alt="EN" />English</button>
            <button onClick={(e) => changeLanguage('fr', e)}><img src="/flag_fr.png" alt="FR" />Français</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderMobile;
