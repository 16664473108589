import React, {useContext, useState, useEffect, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import Filters from './Filters';
import '../styles/Header.css';
import { AuthContext } from '../AuthProvider';

const Header = ({ setSearch, setShowRegister, setShowAlertplan, setShowOverlay, selectedNiche, setSelectedNiche, filters, setFilters, selectedLang, setSelectedLang, selectedCountry, setSelectedCountry, selectedTimerange, setSelectedTimerange }) => {

  const [searchField, setSearchField] = useState('');
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);
    
  const { logout, rank, dl_count, follow_count } = useContext(AuthContext);

  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const languageMenuRef = useRef();

  const location = useLocation();
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    if (rank < 5) {
      setShowAlertplan(true);
      setShowOverlay(true);
    }
    else {
      setSearchField(event.target.value);
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter' && rank >= 10) {
      setSearch(searchField);
    }
  };

  const handleLoginLogout = () => {
    if (localStorage.getItem('token')) {
      logout(handleLogoutSuccess);
    } else {
      setShowRegister(true);
      setShowOverlay(true);
    }
  };

  const handleLogoutSuccess = () => {
    navigate('/');
    setShowRegister(false);
    setShowOverlay(false);
  };

  useEffect(() => {
    const closeMenu = (event) => {
      if (showLanguageMenu && languageMenuRef.current && !languageMenuRef.current.contains(event.target)) {
        setShowLanguageMenu(false);
      }
    };
  
    document.addEventListener('mousedown', closeMenu);
    return () => document.removeEventListener('mousedown', closeMenu);
  }, [showLanguageMenu]);  

  const changeLanguage = (lang, event) => {
    event.stopPropagation();
    i18n.changeLanguage(lang);
    setShowLanguageMenu(false);
    localStorage.setItem('language', lang);
  };

  const getMaxDl = () => {
    if (rank >= 10) {
      return '80'
    }
    else if (rank >= 5) {
      return '50'
    }
    else {
      return '20'
    }
  }

  const getMaxFollow = () => {
    if (rank >= 10) {
      return '40'
    }
    else if (rank >= 5) {
      return '20'
    }
    else {
      return '5'
    }
  }

  return (
    <div className="header">
        <div className="headerLeft">
          {(!["/analyse", "/top_creator", "/download"].includes(location.pathname)) && !location.pathname.match(/^\/analyse_account\/\d+$/) ?
            <>
              <img src="/search.svg" alt="search" className="searchIcon"/>
              <input 
                type="text" 
                placeholder={t("Search...")}
                className="searchInput" 
                value={searchField}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
              <Filters selectedNiche={selectedNiche} setSelectedNiche={setSelectedNiche} filters={filters} setFilters={setFilters} selectedLang={selectedLang} setSelectedLang={setSelectedLang} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} selectedTimerange={selectedTimerange} setSelectedTimerange={setSelectedTimerange} />
            </>
            :
            <>
              <div className='header_account_followed'>{t('Accounts followed')} : <span className='color_red'>{follow_count}/{getMaxFollow()}</span></div>
              <div className='header_download_stats'>{t('Videos downloaded')} : <span className='color_red'>{dl_count}/{getMaxDl()}</span></div>
            </>
          }
        </div>
        <div className='headerRight'>
          {localStorage.getItem('token') ?
            <button className="loginButton" onClick={handleLoginLogout}>
                <img src="/login.svg" alt="logout" className="loginIcon"/>{t('Logout')}
            </button>
            :
            <button className="loginButton" onClick={handleLoginLogout}>
                <img src="/login.svg" alt="login" className="loginIcon"/>{t('Login')}
            </button>
          }
          <div className='flag_icon' onClick={() => setShowLanguageMenu(!showLanguageMenu)}>
            <img src={`/flag_${currentLanguage}.png`} alt={currentLanguage} />
          </div>
          {showLanguageMenu && (
            <div ref={languageMenuRef} className="languageMenu">
              <button onClick={(e) => changeLanguage('en', e)}><img src="/flag_en.png" alt="EN" />English</button>
              <button onClick={(e) => changeLanguage('fr', e)}><img src="/flag_fr.png" alt="FR" />Français</button>
            </div>
          )}
        </div>
    </div>
  );
};

export default Header;
