import React from 'react';
import { useTranslation } from 'react-i18next';
import VideoCard from './VideoCard';
import '../styles/VideoGrid.css';

const VideoGrid = ({ videos, isSearchInProgress }) => {

  const { t } = useTranslation();

  return (
    <div className="video_grid">
      <h2>{t('Videos')}</h2>
      {
        videos.map((video) => (
          <VideoCard key={video.id} video={video} />
        ))
      }
      {isSearchInProgress &&
        <div className="loading-container">
          <div className="loader"></div>
          <div className="loading-text">{t('Loading in progress...')}</div>
        </div>
      }
    </div>
  );
};

export default VideoGrid;