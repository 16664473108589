import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import CreatorCard from './CreatorCard';
import '../styles/TopCreator.css';

const TopCreator = ({ creators, selectRegion, setSelectRegion }) => {
  const { t, i18n } = useTranslation();

  const today = new Date();
  const lastWeek = new Date(new Date().setDate(today.getDate() - 7));

  const formatDate = (date, locale = 'en-US') => {
    return new Intl.DateTimeFormat(locale, { day: '2-digit', month: '2-digit', year: 'numeric' }).format(date);
  };

  const currentLanguage = i18n.language;

  const getDatesLocalized = () => {
    let locale = 'en-US';
    if (currentLanguage === 'fr') {
      locale = 'fr-FR';
    }
    return t('From') + ' ' + formatDate(lastWeek, locale) + ' ' + t('to') + ' ' + formatDate(today, locale);
  }

  const handleRegionClick = (region) => {
    setSelectRegion(region);
  }

  useEffect(() => {
    getDatesLocalized()
  }, [currentLanguage]);

  return (
    <div className="top_creator">
      <h2>{t('Top Creators')}</h2>
      <div className='content_wrapper'>
        <div className='top_creators_title'>
          {t('Found out the top 10 account detected by our IA this week')}<br />
          {getDatesLocalized()}
        </div>
        <div className='creators_filter_countries'>
          <button className={selectRegion === 'US' ? 'country_creator_filter active' : 'country_creator_filter'} onClick={() => handleRegionClick('US')}>
            {t('US Market')}
            <img src="/creator_us.svg" alt="" />
          </button>
          <button className={selectRegion === 'EU' ? 'country_creator_filter active' : 'country_creator_filter'} onClick={() => handleRegionClick('EU')}>
            {t('EU Market')}
            <img src="/creator_eu.svg" alt="" />
          </button>
          <button className={selectRegion === 'GB' ? 'country_creator_filter active' : 'country_creator_filter'} onClick={() => handleRegionClick('GB')}>
            {t('UK Market')}
            <img src="/creator_uk.svg" alt="" />
          </button>
        </div>
      </div>
      <div className='creator_cards_wrapper'>
        <table>
          <thead><tr>
            <td>#</td>
            <td>{t('Profile')}</td>
            <td>{t('Nickname')}</td>
            <td>{t('Sales Revenus')}</td>
            <td>{t('Number of views')}</td>
            <td>{t('Action')}</td>
          </tr></thead>
          <tbody>
            {creators.map((creator, index) => (
              <CreatorCard key={creator.uid} creator={creator} number={index + 1} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TopCreator;
