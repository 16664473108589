import React, {useState, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import CreatorCard from './CreatorCard';
import '../styles/Analyse.css';
import { AuthContext } from '../AuthProvider';

const Analyse = ({creators, refreshFollows}) => {

  const [accountUrl, setAccountUrl] = useState('');
  const [loadingAccount, setLoadingAccount] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { t } = useTranslation();
  
  const { rank, follow_count } = useContext(AuthContext);

  const backendUrl = process.env.REACT_APP_BACK_API_URL;

  const navigate = useNavigate();

  const handleAnalyse = async () => {
    setLoadingAccount(true);
    const accountTag = accountUrl.split('@')[1].split('/')[0];
    console.log(accountTag);
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${backendUrl}/get_creator_by_name/${accountTag}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        navigateToCreator(accountTag, 0);
      }
    } catch (error) {
      console.error('Error:', error);
      if (error.data.error) {
        setErrorMessage(t(error.data.error));
      }
      else {
        setErrorMessage(t('error_account_not_found'));
      }
      setLoadingAccount(false);
    }
  }
  
  const navigateToCreator = async (accountTag, currentCountLoop = 0) => {
    if (currentCountLoop < 5) {
      setTimeout(async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${backendUrl}/get_creator_uid_by_name/${accountTag}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
          const get_uid = await response.json();
          if (get_uid && get_uid.uid_tiktok) {
            const route = `${process.env.REACT_APP_BACK_API_URL}/add_follow`;

            const returnData = await axios.post(route, {
              creator_id: get_uid.uid_tiktok
            }, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            if (returnData.data.success){
              navigate('/analyse_account/' + get_uid.uid_tiktok);
            }
            else {
              setErrorMessage(t(returnData.data.error));
            }
          } else {
            navigateToCreator(accountTag, currentCountLoop + 1);
          }
        } catch (e) {
          navigateToCreator(accountTag, currentCountLoop + 1);
        }
      }, 2000);
    } else {
      setErrorMessage(t('Error while scrape the creator. Please try again later.'));
      setLoadingAccount(false);
    }
  };

  const getRankName = () => {
    if (rank === 3) {
      return t('Standart');
    } else if (rank === 5) {
      return t('Advanced');
    } else if (rank >= 7) {
      return t('Expert');
    } else {
      return t('Free');
    }
  }

  const getFollowLeft = () => {
    if (rank >= 7) {
      return 40 - follow_count;
    } else if (rank === 5) {
      return 20 - follow_count;
    } else if (rank === 3) {
      return 5 - follow_count;
    } else {
      return 0;
    }
  }

  const getMaxFollow = () => {
    if (rank >= 7) {
      return 40;
    } else if (rank === 5) {
      return 20;
    } else if (rank === 3) {
      return 5;
    } else {
      return 0;
    }
  }

  return (
    <div className="analyse">
      <h2>{t('Creator Analyse')}</h2>
      <div className='analyse_page'>
        <div className='analyse_header'>
          <div className='analyse_preview'>
            <h4>{t('Usage preview')}</h4>
            <div className='analyse_use'>
              {t('With your plan')} <span className='color_red'>{getRankName()}</span> {t('you can follow up to')} <span className='color_red'>{getMaxFollow()} {t('accounts')}</span> {t('in real time')}.
            </div>
            <div className='following_stats'>
              <table>
                <thead><tr><th>{t('Followed')}</th><th>{t('Left')}</th></tr></thead>
                <tbody><tr><td>{follow_count}</td><td>{getFollowLeft()}</td></tr></tbody>
              </table>
            </div>
          </div>
          <div className='analyse_form'>
            <h4>{t('Analyse an account')}</h4>
            <div className='analyse_form_text'>{t('Tag of the account')}</div>
            <div className='analyse_form_input'><input type="text" value={accountUrl} onChange={(e) => setAccountUrl(e.target.value)} /></div>
            <div className='analyse_form_button'>
              {loadingAccount &&
                <span className="loader"></span>
              }
              <button className='creator_scrape' onClick={() => handleAnalyse()} disabled={loadingAccount}><img src="/eye.svg" alt="" />{t('Analyse')}</button>
            </div>
            {errorMessage &&
              <div className='error_message'>{errorMessage}</div>
            }
          </div>
        </div>
        <div className='follows_account'>
            <h4>{t('Your followed accounts')}</h4>
            <table>
              <thead><tr>
                <th>{t('Profile')}</th>
                <th>{t('Nickname')}</th>
                <th>{t('Sales Revenus')}</th>
                <th>{t('Number of views')}</th>
                <th>{t('Actions')}</th>
              </tr></thead>
              <tbody>
                {creators.map((creator) => (
                  <CreatorCard key={creator.uid} creator={creator} refreshFollows={refreshFollows} />
                ))}
              </tbody>
            </table>
        </div>
      </div>
    </div>
  );
};

export default Analyse;
