import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import '../styles/AlertPlan.css';

const AlertPlan = ({setShowAlertplan, setShowOverlay}) => {

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handlePopupClose = () => {
    setShowAlertplan(false);
    setShowOverlay(false);
  };

  const handleGoToSubscription = () => {
    navigate('/subscription');
    setShowAlertplan(false);
    setShowOverlay(false);
  };

  return (
    <div className="alert_plan">
      <div className="alert_plan_close" onClick={() => handlePopupClose()}>x</div>
      <div className='alert_plan_title'>
        {t('This feature is not available.')}
      </div>
      <div className='alert_plan_subtitle'>
        {t('To access this feature, upgrade your subscription.')}
      </div>
      <div className='alert_plan_button'>
        <button className="validateButton" onClick={() => handleGoToSubscription()}>{t('Upgrade your plan')}</button>
      </div>
    </div>
  );
};

export default AlertPlan;
