import React from 'react';
import { useTranslation } from 'react-i18next';
import VideoCard from './VideoCard';
import '../styles/TopWeek.css';

const TopWeek = ({ videos }) => {
  const sortedVideos = [...videos].sort((a, b) => b.nb_view - a.nb_view);
  const topVideos = sortedVideos.slice(0, 16);

  const { t } = useTranslation();

  return (
    <div className="top_week">
      <h2>{t('Top Videos')}</h2>
      {topVideos.map((video) => (
        <VideoCard key={video.id} video={video} />
      ))}
    </div>
  );
};

export default TopWeek;
