import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import VideoGrid from './components/VideoGrid';
import Affiliate from './components/Affiliate';
import Profile from './components/Profile';
import Download from './components/Download';
import Subscription from './components/Subscription';
import TopWeek from './components/TopWeek';
import TopCreator from './components/TopCreator';
import Analyse from './components/Analyse';
import AnalyseAccount from './components/AnalyseAccount';
import Favorite from './components/Favorite';
import Sidebar from './components/Sidebar';
import SidebarMobile from './components/SidebarMobile';
import HeaderMobile from './components/HeaderMobile';
import Header from './components/Header';
import Footer from './components/Footer';
import Register from './components/Register';
import ResetPassword from './components/ResetPassword';
import AlertPlan from './components/AlertPlan';
import Loginbar from './components/Loginbar';
import Terms from './components/Terms';
import Thanks from './components/Thanks';
import Admin from './components/Admin';
import { AuthContext } from './AuthProvider';

const MainContent = () => {
  const [videos, setVideos] = useState([]);
  const [originalVideos, setOriginalVideos] = useState([]);
  const [creators, setCreators] = useState([]);
  const [originalCreators, setOriginalCreators] = useState([]);
  const lastLoadedVideosRef = useRef([]);
  const lastLoadedCreatorsRef = useRef([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [registerForm, setRegisterForm] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showAlertplan, setShowAlertplan] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isSearchInProgress, setIsSearchInProgress] = useState(false);
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [resetToken, setResetToken] = useState('');

  const [selectedNiche, setSelectedNiche] = useState('');
  const [selectedLang, setSelectedLang] = useState('all');
  const [selectedCountry, setSelectedCountry] = useState('all');
  const [filters, setFilters] = useState({
    views: 0,
    likes: 0,
    comments: 0,
    shares: 0,
    bookmarks: 0,
    duration: 0
  });
  const [selectedTimerange, setSelectedTimerange] = useState('all');

  const [selectRegion, setSelectRegion] = useState('US');

  const { isLoading, rank, logout } = useContext(AuthContext);

  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const languageMenuRef = useRef();

  const location = useLocation();
  const navigate = useNavigate();
  
  const backendUrl = process.env.REACT_APP_BACK_API_URL;

  const getActiveClass = (path) => {
    if (path === '/' && location.pathname === '/') {
      return 'item-link active';
    } else if (path !== '/' && location.pathname.startsWith(path)) {
      return 'item-link active';
    } else {
      return 'item-link';
    }
  };

  useEffect(() => {
    setToken(localStorage.getItem('token'));
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  useEffect(() => {
    if (token) {
      setShowRegister(false);
      setShowOverlay(false);
    }
  }, [token]);

  useEffect(() => {
    setIsDataLoaded(false);
    setIsSearchInProgress(true);
  }, [selectedNiche]);

  useEffect(() => {
    if (selectedNiche && selectedNiche !== '') {
      navigate('/');
      const fetchData = async () => {
        try {
          setIsSearchInProgress(true);
          const token = localStorage.getItem('token');
          const response = await axios.get(`${backendUrl}/get`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            params: {
              keyword: selectedNiche
            }
          });
          let fetchedVideos = response.data;

          setOriginalVideos(fetchedVideos);
          if (fetchedVideos.length > 0) {
            if (lastLoadedVideosRef.current.length === fetchedVideos.length) {
              setIsDataLoaded(true);
              setIsSearchInProgress(false);
            }
            lastLoadedVideosRef.current = fetchedVideos;
            fetchedVideos = applyFilters(fetchedVideos);
            setVideos(fetchedVideos);
          }
        } catch (error) {
          console.error('Error fetching data: ', error);
          setIsSearchInProgress(false);
        }
      };

      if (!isDataLoaded) {
        fetchData();
        const intervalId = setInterval(() => {
          if (!isDataLoaded) {
            fetchData();
          } else {
            clearInterval(intervalId);
          }
        }, 3500);

        return () => clearInterval(intervalId);
      }
    }
  }, [selectedNiche, isDataLoaded]);

  useEffect(() => {
    if (selectRegion && selectRegion !== '' && location.pathname === "/top_creator") {
      const fetchData = async () => {
        try {
          setIsSearchInProgress(true);
          const token = localStorage.getItem('token');
          const response = await axios.get(`${backendUrl}/top_creator`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            params: {
              region: selectRegion
            }
          });
          let fetchedCreators = response.data;

          setOriginalCreators(fetchedCreators);
          if (fetchedCreators.length > 0) {
            if (lastLoadedCreatorsRef.current.length === fetchedCreators.length) {
              setIsDataLoaded(true);
              setIsSearchInProgress(false);
            }
            lastLoadedCreatorsRef.current = fetchedCreators;
          }
          setCreators(fetchedCreators);
        } catch (error) {
          console.error('Error fetching data: ', error);
          setIsSearchInProgress(false);
        }
      };

      if (!isDataLoaded) {
        fetchData();
        const intervalId = setInterval(() => {
          if (!isDataLoaded) {
            fetchData();
          } else {
            clearInterval(intervalId);
          }
        }, 3500);

        return () => clearInterval(intervalId);
      }

      fetchData();
    }
  }, [selectRegion, isDataLoaded]);

  useEffect(() => {
    if (search && search!== '') {
      navigate('/');
      const fetchData = async () => {
        try {
          const token = localStorage.getItem('token');
          await axios.get(`${backendUrl}/search`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            params: {
              keyword: search
            }
          });
          setSelectedNiche(search);
        } catch (error) {
          console.error('Error fetching data: ', error);
        }
      };

      fetchData();
    }
  }, [search]);

  useEffect(() => {
    const filteredVideos = applyFilters(originalVideos);
    setVideos(filteredVideos);
  }, [filters, selectedLang, selectedCountry, selectedTimerange, originalVideos]);

  const applyFilters = (videos) => {
    return videos.filter(video => {
      const filterViews = filters.views === 0 || video.nb_view >= filters.views * 1000;
      const filterLikes = filters.likes === 0 || video.nb_like >= filters.likes * 1000;
      const filterComments = filters.comments === 0 || video.nb_comm >= filters.comments * 1000;
      const filterDuration = filters.duration === 0 || video.duration >= filters.duration * 60000;
  
      let filterDate = true;
      if (selectedTimerange > 0) {
        const dateLimit = (new Date().getTime() - (selectedTimerange * 24 * 60 * 60 * 1000)) / 1000;
        filterDate = video.publish_tiktok >= dateLimit;
      }
  
      let filterLang = true;
      if (selectedLang !== 'all') {
        filterLang = video.lang === selectedLang;
      }

      let filterCountry = true;
      if (selectedCountry!== 'all') {
        filterCountry = video.region === selectedCountry;
      }
      
      return filterViews && filterLikes && filterComments && filterDuration && filterDate && filterLang && filterCountry;
    });
  };

  const refreshFollows = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${backendUrl}/follows`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      let fetchedCreators = response.data;
      setOriginalCreators(fetchedCreators);
      setCreators(fetchedCreators);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };
  
  useEffect(() => {
    if (!isLoading) {
      const fetchData = async (path) => {
        setIsMenuOpen(false);
        setRegisterForm(false);
        if (!localStorage.getItem('token')) {
          if (["/favorites", "/top_videos", "/top_creator", "/download", "/analyse"].includes(path)) {
            navigate('/');
            setShowRegister(true);
            setRegisterForm(true);
            setShowOverlay(true);
            return;
          }
          if (path === "/admin") {
            navigate('/');
            return;
          }
        }
        else if (rank === 0) {
          if (["/favorites", "/top_videos", "/top_creator", "/download"].includes(path)) {
            navigate('/');
            setShowAlertplan(true);
            setShowOverlay(true);
            return;
          }
        }
        else if (rank < 10 && path === "/top_videos") {
          setShowAlertplan(true);
          setShowOverlay(true);
          return;
        }
        else if (rank < 5 && path === "/favorites") {
          setShowAlertplan(true);
          setShowOverlay(true);
          return;
        }
        else if (rank < 99 && path === "/admin") {
          navigate('/');
          return;
        }

        setSelectedNiche('');
        setSearch('');
        let url = '';
        if (path === '/favorites') {
          url = '/favoris';
        }
        else if (path === '/top_videos') {
          url = '/top_week';
        }
        else if (path === '/top_creator') {
          url = '/top_creator';
        }
        else if (path === '/analyse') {
          url = '/follows';
        }
    
        try {
          if (localStorage.getItem('token') && url !== '') {
            const fetchData = async () => {
              try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${backendUrl}${url}`, {
                  headers: {
                    'Authorization': `Bearer ${token}`,
                  }
                });
                if (path === '/top_creator' || path === '/analyse') {
                  let fetchedCreators = response.data;
                  setOriginalCreators(fetchedCreators);
                  setCreators(fetchedCreators);
                }
                else {
                  let fetchedVideos = response.data;
                  setOriginalVideos(fetchedVideos);
                  setVideos(fetchedVideos);
                }
              } catch (error) {
                console.error('Error fetching data: ', error);
              }
            };
      
            fetchData();
          }
          else if (localStorage.getItem('token') && url === '' && rank > 0) {
            const fetchData = async () => {
              try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${backendUrl}/discover`, {
                  headers: {
                    'Authorization': `Bearer ${token}`,
                  }
                });
                let fetchedVideos = response.data;
                setOriginalVideos(fetchedVideos);
                setVideos(fetchedVideos);
                setIsDataLoaded(true);
                setIsSearchInProgress(false);
              } catch (error) {
                console.error('Error fetching data: ', error);
              }
            };
      
            fetchData();
          }
          else {
            const videos_random = [{
              id: 1,
              desc: 'test',
              lang: 'en',
              duration: '47000',
              url: '#',
              nb_view: Math.floor(Math.random() * 900 + 100),
              nb_like: Math.floor(Math.random() * 900 + 100),
              nb_comm: Math.floor(Math.random() * 900 + 100),
              nb_share: Math.floor(Math.random() * 90 + 10),
              cover: 'https://picsum.photos/200/300?random=1'
            },{
              id: 2,
              desc: 'test',
              lang: 'en',
              duration: '57000',
              url: '#',
              nb_view: Math.floor(Math.random() * 900 + 100),
              nb_like: Math.floor(Math.random() * 900 + 100),
              nb_comm: Math.floor(Math.random() * 900 + 100),
              nb_share: Math.floor(Math.random() * 90 + 10),
              cover: 'https://picsum.photos/200/300?random=2'
            },{
              id: 3,
              desc: 'test',
              lang: 'en',
              duration: '67000',
              url: '#',
              nb_view: Math.floor(Math.random() * 900 + 100),
              nb_like: Math.floor(Math.random() * 900 + 100),
              nb_comm: Math.floor(Math.random() * 900 + 100),
              nb_share: Math.floor(Math.random() * 90 + 10),
              cover: 'https://picsum.photos/200/300?random=3'
            },{
              id: 4,
              desc: 'test',
              lang: 'en',
              duration: '77000',
              url: '#',
              nb_view: Math.floor(Math.random() * 900 + 100),
              nb_like: Math.floor(Math.random() * 900 + 100),
              nb_comm: Math.floor(Math.random() * 900 + 100),
              nb_share: Math.floor(Math.random() * 90 + 10),
              cover: 'https://picsum.photos/200/300?random=4'
            },{
              id: 5,
              desc: 'test',
              lang: 'en',
              duration: '87000',
              url: '#',
              nb_view: Math.floor(Math.random() * 900 + 100),
              nb_like: Math.floor(Math.random() * 900 + 100),
              nb_comm: Math.floor(Math.random() * 900 + 100),
              nb_share: Math.floor(Math.random() * 90 + 10),
              cover: 'https://picsum.photos/200/300?random=5'
            },{
              id: 6,
              desc: 'test',
              lang: 'en',
              duration: '97000',
              url: '#',
              nb_view: Math.floor(Math.random() * 900 + 100),
              nb_like: Math.floor(Math.random() * 900 + 100),
              nb_comm: Math.floor(Math.random() * 900 + 100),
              nb_share: Math.floor(Math.random() * 90 + 10),
              cover: 'https://picsum.photos/200/300?random=6'
            }];
            setOriginalVideos(videos_random);
            setVideos(videos_random);
            setIsDataLoaded(true);
            setIsSearchInProgress(false);
          }
        } catch (error) {
          console.error('Error fetching data: ', error);
        }
      };
    
      fetchData(location.pathname);
    }
  }, [location.pathname, rank, isLoading]);

  const handleClickOverlay = () => {
    setShowOverlay(false);
    setShowRegister(false);
    setShowAlertplan(false);
    navigate('/');
  };

  const handleOnOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  const handleLoginLogout = () => {
    if (localStorage.getItem('token')) {
      logout(handleLogoutSuccess);
    } else {
      setShowRegister(true);
      setShowOverlay(true);
    }
  };

  const handleLogoutSuccess = () => {
    navigate('/');
    setShowRegister(false);
    setShowOverlay(false);
  };

  useEffect(() => {
    const closeMenu = (event) => {
      if (showLanguageMenu && languageMenuRef.current && !languageMenuRef.current.contains(event.target)) {
        setShowLanguageMenu(false);
      }
    };
  
    document.addEventListener('mousedown', closeMenu);
    return () => document.removeEventListener('mousedown', closeMenu);
  }, [showLanguageMenu]);  

  const changeLanguage = (lang, event) => {
    event.stopPropagation();
    i18n.changeLanguage(lang);
    setShowLanguageMenu(false);
    localStorage.setItem('language', lang);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    if (location.pathname === '/reset_password' && token) {
      setShowResetPassword(true);
      setShowOverlay(true);
      setResetToken(token);
    } else {
      setShowResetPassword(false);
      setShowOverlay(false);
    }
  }, [location]);  

  return (
    <>
        {showOverlay && <div className="overlay" onClick={() => handleClickOverlay()}></div>}
        <Sidebar getActiveClass={getActiveClass} />
        <SidebarMobile isMenuOpen={isMenuOpen} handleOnOpen={handleOnOpen} getActiveClass={getActiveClass} />
        <div className={isMenuOpen ? 'main_content no_scroll' : 'main_content'}>
          <HeaderMobile changeLanguage={changeLanguage} setShowLanguageMenu={setShowLanguageMenu} handleLoginLogout={handleLoginLogout} showLanguageMenu={showLanguageMenu} currentLanguage={currentLanguage} languageMenuRef={languageMenuRef} />
          {location.pathname !== '/profile' &&
              <Header search={search} setSearch={setSearch} setShowRegister={setShowRegister} setShowAlertplan={setShowAlertplan} setShowOverlay={setShowOverlay} selectedNiche={selectedNiche} setSelectedNiche={setSelectedNiche} filters={filters} setFilters={setFilters} selectedLang={selectedLang} setSelectedLang={setSelectedLang} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} selectedTimerange={selectedTimerange} setSelectedTimerange={setSelectedTimerange} />
          }
          <Routes>
              <Route path="/" element={<VideoGrid videos={videos} isSearchInProgress={isSearchInProgress} />} />
              <Route path="/top_videos" element={<TopWeek videos={videos} />} />
              <Route path="/top_creator" element={<TopCreator creators={creators} selectRegion={selectRegion} setSelectRegion={setSelectRegion} />} />
              <Route path="/favorites" element={<Favorite videos={videos} />} />
              <Route path="/analyse" element={<Analyse creators={creators} refreshFollows={refreshFollows} />} />
              <Route path="/analyse_account/:id" element={<AnalyseAccount />} />
              <Route path="/affiliate" element={<Affiliate />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/download" element={<Download />} />
              <Route path="/subscription" element={<Subscription />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/thankyou" element={<Thanks />} />
              {rank >= 99 &&
                <Route path="/admin" element={<Admin/>} />
              }
              <Route path="/reset_password" element={<VideoGrid videos={videos} isSearchInProgress={isSearchInProgress} />} />
          </Routes>
          {!localStorage.getItem('token') && <Loginbar setShowRegister={setShowRegister} setShowOverlay={setShowOverlay} />}
          <Footer />
        </div>
        {showRegister && <Register setShowRegister={setShowRegister} setShowOverlay={setShowOverlay} registerForm={registerForm} />}
        {showAlertplan && <AlertPlan setShowAlertplan={setShowAlertplan} setShowOverlay={setShowOverlay} />}
        {showResetPassword && <ResetPassword token={resetToken} setShowOverlay={setShowOverlay} />}
    </>
  );
};

export default MainContent;
