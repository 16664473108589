import React, { createContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const { t } = useTranslation();
  
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [rank, setRank] = useState(0);
  const [pseudo, setPseudo] = useState(t('Visitor'));
  const [dl_count, setDl_count] = useState(0);
  const [follow_count, setFollow_count] = useState(0);
  const [affiliate, setAffiliate] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const backendUrl = process.env.REACT_APP_BACK_API_URL;

  const handleActionSuccess = (onSuccess) => {
    if (onSuccess) onSuccess();
  };

  const handleActionError = (onError) => {
    if (onError) onError();
  };

  useEffect(() => {
    getUserInfo();

    const queryParams = new URLSearchParams(window.location.search);
    const affiliateParam = queryParams.get('affiliate');
    if (affiliateParam) {
      setAffiliate(affiliateParam);
      localStorage.setItem('affiliate', affiliateParam);
    }
  }, []);

  const getUserInfo = async () => {
    try {
      if (localStorage.getItem('token')) {
        setIsLoading(true);
        const token = localStorage.getItem('token');
        const user_info = await axios.get(`${backendUrl}/user`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });
        if (user_info.data.active !== 1) {
          logout();
          setIsLoading(false);
          window.location.reload();
        }
        setRank(user_info.data.access);
        setPseudo(user_info.data.pseudo);
        setDl_count(user_info.data.count_dl);
        setFollow_count(user_info.data.count_fav_creators);
        setIsLoading(false);
      }
      else {
        setIsLoading(false);
      }
    }
    catch (error) {
      logout();
      setIsLoading(false);
      window.location.reload();
    }
  }

  const login = async (email, password, callback, callback_error) => {
    setSuccessMessage('');
    setErrorMessage('');
    try {
      const response = await fetch(`${backendUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      const data = await response.json();
      if (data.error) {
        setErrorMessage(t(data.error));
        handleActionError(callback_error);
      }
      else if (data.token) {
        localStorage.setItem('token', data.token);
        getUserInfo();
        handleActionSuccess(callback);
      }
    } catch (error) {
      setErrorMessage(t("An error occured, please contact the administrator"));
      console.error("Erreur de connexion", error);
      handleActionError(callback_error);
    }
  };

  const register = async (email, pseudo, password, confirm_password, callback, callback_error) => {
    setSuccessMessage('');
    setErrorMessage('');
    if (password === confirm_password) {
      try {
        let affiliate_param = '';
        if (affiliate) {
          affiliate_param = affiliate;
        }
        else if (localStorage.getItem('affiliate')) {
          affiliate_param = localStorage.getItem('affiliate');
        }
        const response = await fetch(`${backendUrl}/register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, pseudo, password, 'affiliate': affiliate_param }),
        });
        const data = await response.json();
        if (data.error) {
          setErrorMessage(t(data.error));
          handleActionError(callback_error);
        }
        else if (data.token) {
          localStorage.setItem('token', data.token);
          setRank(data.rank);
          setPseudo(pseudo);
          handleActionSuccess(callback);
          window.fpr && window.fpr("referral", { email: email });
        }
      } catch (error) {
        console.error("Erreur de connexion", error);
        setErrorMessage(t("An error occured, please contact the administrator"));
        handleActionError(callback_error);
      }
    } else {
      setErrorMessage(t('The passwords do not match'));
    }
  };

  const reset_password = async (old_password, password, confirm_password, callback_error) => {
    setSuccessMessage('');
    setErrorMessage('');
    if (password === confirm_password) {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${backendUrl}/reset_password`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ old_password, password }),
        });
        const data = await response.json();
        if (data.error) {
          setErrorMessage(t(data.error));
          handleActionError(callback_error);
        }
        else if (data.success) {
          setSuccessMessage(t("Password changed successfully"));
        }
      } catch (error) {
        console.error("Erreur de connexion", error);
        setErrorMessage(t("An error occured, please contact the administrator"));
        handleActionError(callback_error);
      }
    } else {
      setErrorMessage(t('The passwords do not match'));
      handleActionError(callback_error);
    }
  };

  const send_reset_password_email = async (email, callback) => {
    setSuccessMessage('');
    setErrorMessage('');
    try {
      const response = await fetch(`${backendUrl}/forgot_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (data.error) {
        setErrorMessage(t(data.error));
        handleActionError(callback);
      }
      else if (data.success) {
        setSuccessMessage(t("If your email exists, you will receive an email with instructions to reset your password"));
      }
    } catch (error) {
      console.error("Erreur de connexion", error);
      setErrorMessage(t("An error occured, please contact the administrator"));
      handleActionError(callback);
    }
  };

  const logout = (callback) => {
    localStorage.setItem('token', '');
    setRank(0);
    setPseudo(t('Visitor'));
    handleActionSuccess(callback);
  };

  return (
    <AuthContext.Provider value={{ isLoading, successMessage, errorMessage, login, logout, register, reset_password, send_reset_password_email, rank, setRank, pseudo, dl_count, follow_count }}>
      {children}
    </AuthContext.Provider>
  );
};
