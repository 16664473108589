import React, {useContext, useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import '../styles/Profile.css';
import { AuthContext } from '../AuthProvider';

const Profile = () => {

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errorMessagePage, setErrorMessagePage] = useState('');
  const [successMessagePage, setSuccessMessagePage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const backendUrl = process.env.REACT_APP_BACK_API_URL;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { logout, reset_password, pseudo, rank, successMessage, errorMessage } = useContext(AuthContext);

  const handleCallbackError = () => {
    setIsSubmitting(false);
  };

  const handleChangePassword = () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    setErrorMessagePage('');
    if (oldPassword === '' || newPassword === '' || confirmNewPassword === '') {
      setErrorMessagePage(t('Please fill all fields'));
      setIsSubmitting(false);
    } else if (newPassword!== confirmNewPassword) {
      setErrorMessagePage(t('Passwords do not match'));
      setIsSubmitting(false);
    } else {
      reset_password(oldPassword, newPassword, confirmNewPassword, handleCallbackError);
    }
  };

  const handleCancelSubscription = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    setErrorMessagePage('');
    setSuccessMessagePage('');
    if (oldPassword === '') {
      setErrorMessagePage(t('Please provide your current password'));
      return;
    }
  
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${backendUrl}/cancel_subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ old_password: oldPassword })
      });
  
      const data = await response.json();
      if (data.error) {
        setErrorMessagePage(data.error);
      }
      else {
        setSuccessMessagePage(t('Subscription cancelled successfully'));
      }
      setIsSubmitting(false);
    } catch (error) {
      setErrorMessagePage(error.message);
      setIsSubmitting(false);
    }
  };
  
  const handleDeleteAccount = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    setErrorMessagePage('');
    if (oldPassword === '') {
      setErrorMessagePage(t('Please provide your current password'));
      return;
    }
  
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${backendUrl}/delete_user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ password: oldPassword })
      });
  
      const data = await response.json();
      if (data.error) {
        setErrorMessagePage(data.error);
      } else {
        logout(handleLogoutSuccess);
        setSuccessMessagePage(t('Account deleted successfully'));
      }
      setIsSubmitting(false);
    } catch (error) {
      setErrorMessagePage(error.message);
      setIsSubmitting(false);
    }
  };

  const handleLogoutSuccess = () => {
    navigate('/');
  };

  const currentPlan = () => {
    if (rank === 1) {
      return t('Free');
    } else if (rank === 3) {
      return t('Standart');
    } else if (rank === 5) {
      return t('Advanced');
    } else if (rank === 10) {
      return t('Expert');
    } else {
      return t("You don't have any plan");
    }
  };

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/');
    }
    setErrorMessagePage('');
    setSuccessMessagePage('');
    setOldPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
  }, []);

  return (
    <div className="profile">
      <h2>{t('Your Profile')}: {pseudo}</h2>
      <h3>{t('Here you can change your password and delete your account.')}</h3>
      <div className='profile_info'>
        {t('Your current plan is')}: {currentPlan()}
      </div>
      <div className='profile_old_password'>
        <label>{t('In order to do any of the next actions you have to provide your current password')}:</label>
        <input type="password" placeholder='Old password' value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
      </div>
      {errorMessage && <div className='profile_error_message'>{errorMessage}</div>}
      {errorMessagePage && <div className='profile_error_message'>{errorMessagePage}</div>}
      {successMessage && <div className='profile_success_message'>{successMessage}</div>}
      {successMessagePage && <div className='profile_success_message'>{successMessagePage}</div>}
      <div className='profile_actions'>
        <input type="password" placeholder='New password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
        <input type="password" placeholder='Confirm new password' value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
        <button onClick={() => handleChangePassword()}>{t('Change password')}</button>
      </div>
      <div className='warning_message'>
        <p>{t('Warning')}:</p>
        <p>{t("The next actions can't be undone and will be immediate!")}</p>
        <p>{t('For the delete action, you will be logged out after the action.')}</p>
      </div>
      <div className='profile_actions'>
        <button onClick={handleCancelSubscription}>{t('Cancel plan')}</button>
      </div>
      <div className='profile_actions'>
        <button onClick={handleDeleteAccount}>{t('Delete account')}</button>
      </div>
    </div>
  );
};

export default Profile;
