import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import '../styles/Register.css';
import { AuthContext } from '../AuthProvider';

const Register = ({setShowRegister, setShowOverlay, registerForm}) => {
  const [isLogin, setIsLogin] = useState(true);
  const [pseudo, setPseudo] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirm_password, setConfirm_password] = useState('');
  const [errorMessagePage, setErrorMessagePage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  
  const { login, register, send_reset_password_email, successMessage, errorMessage } = useContext(AuthContext);

  const { t } = useTranslation();

  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{7,}$/;

  const navigate = useNavigate();

  const validateEmail = (email) => {
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    return passwordRegex.test(password);
  };

  const handleLoginRegisterSuccess = () => {
    navigate('/');
    setShowRegister(false);
    setShowOverlay(false);
    setIsSubmitting(false);
  };

  const handleLoginRegisterError = () => {
    setIsSubmitting(false);
  };

  const handleResetLinkCallback = () => {
    setIsSubmitting(false);
  };

  const handleLogin = () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    if (email === '' || password === '') {
      setErrorMessagePage(t('Please fill all fields'));
      setIsSubmitting(false);
    } else if (!validateEmail(email)) {
      setErrorMessagePage(t('Invalid email format'));
      setIsSubmitting(false);
    } else {
      setErrorMessagePage('');
      login(email, password, handleLoginRegisterSuccess, handleLoginRegisterError);
    }
  };

  const handleRegister = () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    if (email === '' || pseudo === '' || password === '' || confirm_password === '') {
      setErrorMessagePage(t('Please fill all fields'));
      setIsSubmitting(false);
    } else if (password!== confirm_password) {
      setErrorMessagePage(t('Passwords do not match'));
      setIsSubmitting(false);
    } else if (!validateEmail(email)) {
      setErrorMessagePage(t('Invalid email format'));
      setIsSubmitting(false);
    } /*else if (!validatePassword(password)) {
      setErrorMessagePage(t('Password no strong enough'));
      setIsSubmitting(false);
    } */ else {
      setErrorMessagePage('');
      register(email, pseudo, password, confirm_password, handleLoginRegisterSuccess, handleLoginRegisterError);
    }
  };

  const handlePopupClose = () => {
    setShowRegister(false);
    setShowOverlay(false);
  };

  const toggleView = () => {
    setIsLogin(!isLogin);
  };

  const handleForgotPasswordClick = () => {
    setShowForgotPassword(true);
  };
  
  const handleBackClick = () => {
    setShowForgotPassword(false);
  };

  const handleSendResetLink = () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    if (email === '') {
      setErrorMessagePage(t('Please fill all fields'));
      setIsSubmitting(false);
    } else if (!validateEmail(email)) {
      setErrorMessagePage(t('Invalid email format'));
      setIsSubmitting(false);
    } else {
      setErrorMessagePage('');
      send_reset_password_email(email, handleResetLinkCallback);
      setIsSubmitting(false);
    }
  };  

  useEffect(() => {
    if (registerForm) {
      setIsLogin(false);
    }
    else {
      setIsLogin(true);
    }
  }, [registerForm]);

  return (
    <div className="register">
      <div className="register_close" onClick={() => handlePopupClose()}>x</div>
      <div className='register_logo'><img src='/logo_big.png' alt='logo' /></div>
      {showForgotPassword ? (
        <>
          <div className='forgot_password_title'>
            <h2>{t('Reset Password')}</h2>
            <h3>{t('Enter your email to receive your reset link')}</h3>
          </div>
          <div className='forgot_password_input'>
            <input type="text" placeholder={t("Email")} value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div className='success_message'>{t(successMessage)}</div>
          <div className='error_message'>{t(errorMessage)}</div>
          <div className='error_message'>{t(errorMessagePage)}</div>
          <div className='validate_button'>
            <button className="validateButton" disabled={isSubmitting} onClick={handleSendResetLink}>{t('Send Reset Link')}</button>
          </div>
          <div className='back_link'>
            <button className='fortgot_password_button' onClick={handleBackClick}>{t('Back')}</button>
          </div>
        </>
      ) : (
        <>
          <div className='register_buttons'>
            <button className={`loginButton ${isLogin ? 'active' : ''}`} onClick={toggleView}>{t('Login')}</button>
            <button className={`registerButton ${!isLogin ? 'active' : ''}`} onClick={toggleView}>{t('Register')}</button>
          </div>
          {isLogin ? (
            <>
              <div className='login_title'>
                <h2>{t('Welcome Back!')}</h2>
                <h3>{t('Log in to continue')}</h3>
              </div>
              <div className='login_input'>
                <input type="text" placeholder={t("Email")} value={email} onChange={(e) => setEmail(e.target.value)} />
                <input type="password" placeholder={t("Password")} value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
              <div className='error_message'>{t(errorMessage)}</div>
              <div className='error_message'>{t(errorMessagePage)}</div>
              <div className='validate_button'>
                <button className="validateButton" disabled={isSubmitting} onClick={() => handleLogin()}>{t('Login')}</button>
              </div>
            </>
          ) : (
            <>
              <div className='register_title'>
                <h2>{t('Create an account')}</h2>
                <h3>{t('Sign up to start!')}</h3>
              </div>
              <div className='register_input'>
                <input type="text" placeholder={t("Email")} value={email} onChange={(e) => setEmail(e.target.value)} />
                <input type="text" placeholder={t("Login_id")} value={pseudo} onChange={(e) => setPseudo(e.target.value)} />
                <input type="password" placeholder={t("Password")} value={password} onChange={(e) => setPassword(e.target.value)} />
                <input type="password" placeholder={t("Confirm Password")} value={confirm_password} onChange={(e) => setConfirm_password(e.target.value)} />
              </div>
              <div className='error_message'>{t(errorMessage)}</div>
              <div className='error_message'>{t(errorMessagePage)}</div>
              <div className='validate_button'>
                <button className="validateButton" disabled={isSubmitting} onClick={() => handleRegister()}>{t('Register')}</button>
              </div>
            </>
          )}
          <div className='password_forgotten'>
            <button className='fortgot_password_button' onClick={handleForgotPasswordClick}>{t('Forgotten password?')}</button>
          </div>
        </>
      )}
    </div>
  );
};

export default Register;
