import React from'react';
import { useTranslation } from 'react-i18next';
import '../styles/Thanks.css';

const Terms = () => {

    const { t } = useTranslation();
  
    return (
        <div className="thanks">
            <h2>{t('Thank you for your subscription to SpyTik')}</h2>
            <div className='thanks_title'>{t('Your plan is coming')}</div>
            <div className='thanks_corps'>{t("success_page_text")}</div>
        </div>
    )
}

export default Terms;