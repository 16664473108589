import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/Affiliate.css';

const Affiliate = () => {

  const { t } = useTranslation();

  return (
    <div className="affiliate">
      <h2>{t('Affiliate')}</h2>
      <h3>{t('Join SpyTik Affiliate Program and receive a 50% commission on all payments for paying customers you refer.')}</h3>
      <div className='affiliate_text'>
        {t('Contact us at contact@spytik.com for any question.')}
      </div>
      { localStorage.getItem('token') &&
        <div className='affiliate_link'>
          {t('Get your affiliate link here')}: <a href="https://spytik.firstpromoter.com" target="_blank" rel="noreferrer">https://spytik.firstpromoter.com</a>
        </div>
      }
    </div>
  );
};

export default Affiliate;
