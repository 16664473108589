import React from 'react';
import { useTranslation } from 'react-i18next';
import VideoCard from './VideoCard';
import '../styles/Favorite.css';

const Favorite = ({ videos }) => {

  const { t } = useTranslation();
  
  return (
    <div className="favorite">
      <h2>{t('Favorites')}</h2>
      {videos.map((video) => (
        <VideoCard key={video.id} video={video} />
      ))}
    </div>
  );
};

export default Favorite;
