import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/CreatorCard.css';

const CreatorCard = ({ creator, number = 0, refreshFollows }) => {
  
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleAnalyseClick = async () => {
    try {
      const route = `${process.env.REACT_APP_BACK_API_URL}/add_follow`;

      const token = localStorage.getItem('token');
      const returnData = await axios.post(route, {
        creator_id: creator.uid
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (returnData.data.success){
        navigate('/analyse_account/' + creator.uid);
      }
      else {
        alert(returnData.data.error);
      }
    } catch (error) {
      console.error('Error adding favorite: ', error);
    }
  };

  const handleUnfollowClick = async () => {
    try {
      const route = `${process.env.REACT_APP_BACK_API_URL}/remove_follow`;

      const token = localStorage.getItem('token');
      await axios.post(route, {
        creator_id: creator.uid
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      refreshFollows();
    } catch (error) {
      console.error('Error adding favorite: ', error);
    }
  };

  function formatNumber(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'k';
    } else {
      return num;
    }
  }

  const priceEstimate = (creator.total_views * 0.45 / 1000).toFixed(2);

  const getCover = () => {
    if (creator.url !== '#') {
      return `${process.env.REACT_APP_BACK_API_URL}/tiktok_cover/${creator.cover}`;
    }
    else {
      return creator.cover;
    }
  };

  const getAnalyseTitle = () => {
    if (number === 0) {
      return t('View Analyse');
    }
    else {
      return t('Analyse account');
    }
  };

  return (
    <tr className='creator_card'>
      {number > 0 && 
        <td className='creator_number'>{number}</td>
      }
      <td className='creator_cover'><img src={getCover()} alt="" /></td>
      <td className='creator_nickname'>{creator.nickname}<span><a href={`https://tiktok.com/@${creator.nickname}`} target="_blank" rel="noopener noreferrer">{t('View account')}</a></span></td>
      <td className='creator_revenus'>${priceEstimate}</td>
      <td className='creator_views'>{formatNumber(creator.total_views)}</td>
      <td className='creator_actions'>
        <button className='creator_analyse' onClick={handleAnalyseClick}>{getAnalyseTitle()}<img src="/eye.svg" alt="" /></button>
        {number === 0 &&
          <button className='remove_follow' onClick={handleUnfollowClick}><img src="/trash.svg" alt={t('Unfollow')} /></button>
        }
      </td>
    </tr>
  );
};

export default CreatorCard;
