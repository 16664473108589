import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/Register.css';

const ResetPassword = ({ token, setShowOverlay }) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [successMessagePage, setSuccessMessagePage] = useState('');
  const [errorMessagePage, setErrorMessagePage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const backendUrl = process.env.REACT_APP_BACK_API_URL;

  const handleResetPassword = async () => {
    setIsSubmitting(true);
    setErrorMessagePage('');
    setSuccessMessagePage('');
    if (password === '' || confirmPassword === '') {
      setErrorMessagePage(t('Please fill all fields'));
      setIsSubmitting(false);
    }
    else if (password !== confirmPassword) {
      setErrorMessagePage(t('Passwords do not match'));
      setIsSubmitting(false);
    }
    else {
      try {
        const response = await axios.post(`${backendUrl}/reset_password_token`, {
          token,
          password
        });

        if (response.data.success) {
          setSuccessMessagePage(t(response.data.success));
        } else if (response.data.error) {
          setErrorMessagePage(t(response.data.error));
        }
        else {
          setErrorMessagePage(t('An error occurred while resetting your password. Please try again.'));
        }
        setIsSubmitting(false);
      } catch (error) {
        if (error.response.data.error) {
          setErrorMessagePage(t(error.response.data.error));
        }
        else {
          setErrorMessagePage(t('An error occurred while resetting your password. Please try again.'));
        }
        setIsSubmitting(false);
      }
    }
  };

  const handlePopupClose = () => {
    navigate('/');
    setShowOverlay(false);
  };

  return (
    <div className="register">
    <div className="register_close" onClick={() => handlePopupClose()}>x</div>
    <div className='register_logo'><img src='/logo_big.png' alt='logo' /></div>
      <div className='forgot_password_title'>
        <h2>{t('Reset Password')}</h2>
        <h3>{t('Enter a new password in order to reset it')}</h3>
      </div>
      <div className='register_input'>
        <input type="password" placeholder={t("New Password")} value={password} onChange={(e) => setPassword(e.target.value)} />
        <input type="password" placeholder={t("Confirm New Password")} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
      </div>
      <div className='success_message'>{t(successMessagePage)}</div>
      <div className='error_message'>{t(errorMessagePage)}</div>
      <div className='validate_button'>
        <button className="validateButton" disabled={isSubmitting} onClick={handleResetPassword}>{t('Reset Password')}</button>
      </div>
    </div>
  );
};

export default ResetPassword;
