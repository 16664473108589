import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import '../styles/VideoCard.css';
import { AuthContext } from '../AuthProvider';

const VideoCard = ({ video }) => {

  const [isRemoved, setIsRemoved] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [triedUpdatingCover, setTriedUpdatingCover] = useState(false);
  
  const { rank } = useContext(AuthContext);

  const { t } = useTranslation();

  const handleFavoriteClick = async () => {
    try {
      let route = `${process.env.REACT_APP_BACK_API_URL}/add_favoris`;
      if (video.favoris === true) {
        route = `${process.env.REACT_APP_BACK_API_URL}/remove_favoris`;
        setIsRemoved(true);
      }
      else {
        setIsFavorite(true);
      }

      const token = localStorage.getItem('token');
      await axios.post(route, {
        tiktok_id: video.id
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    } catch (error) {
      console.error('Error adding favorite: ', error);
    }
  };

  function formatNumber(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'k';
    } else {
      return num;
    }
  }

  const priceEstimate = (video.nb_view * 0.45 / 1000).toFixed(2);

  const handleError = async () => {
    if (video.url !== '#') {
      if (!triedUpdatingCover) {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${process.env.REACT_APP_BACK_API_URL}/update_cover`, {
            params: { id: video.id },
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          if (response.data && response.data.cover) {
            video.cover = `${process.env.REACT_APP_BACK_API_URL}/tiktok_cover/${response.data.cover}`;
            setTriedUpdatingCover(true);
          } else {
            setIsRemoved(true);
          }
        } catch (error) {
          console.error('Error updating cover: ', error);
          setIsRemoved(true);
        }
      } else {
        setIsRemoved(true);
      }
    }
  };

  const getCover = () => {
    if (video.url !== '#') {
      return `${process.env.REACT_APP_BACK_API_URL}/tiktok_cover/${video.cover}`;
    }
    else {
      return video.cover;
    }
  };

  return (
    <div className={`video-card ${isRemoved? 'video-removed' : ''}`}>
      { rank >= 10 &&
        <div className='video-card-price'>
          ${priceEstimate}
        </div>
      }
      <div className="video-image">
        <img src={getCover()} alt="" onError={handleError} />
        {/*video.url !== '#' && 
          <div className='video-description'>
            {video.desc.length > 30 ? video.desc.slice(0, 30) + '...' : video.desc}
          </div>
        */}
        {video.url !== '#' && video.tiktok_url !== '#' && rank >= 5 &&
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
            className={`favorite-icon ${video.favoris || isFavorite ? 'fav' : ''}`}
            onClick={handleFavoriteClick}
          >
            <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 18.09l-6.18 3.24L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
          </svg>
        }
      </div>
      <div className="video-info">
        <table><tbody>
          <tr>
            <td>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="17" viewBox="0 0 25 17" fill="none">
                <path d="M12.3102 0.613281C6.89355 0.613281 2.26772 3.98245 0.393555 8.73828C2.26772 13.4941 6.89355 16.8633 12.3102 16.8633C17.7269 16.8633 22.3527 13.4941 24.2269 8.73828C22.3527 3.98245 17.7269 0.613281 12.3102 0.613281ZM12.3102 14.6966C10.3183 14.7032 8.36486 14.1476 6.67459 13.0936C4.98432 12.0396 3.62581 10.5299 2.75522 8.73828C4.54272 5.08745 8.20439 2.77995 12.3102 2.77995C16.4161 2.77995 20.0777 5.08745 21.8652 8.73828C20.9946 10.5299 19.6361 12.0396 17.9459 13.0936C16.2556 14.1476 14.3022 14.7032 12.3102 14.6966ZM12.3102 3.86328C9.62356 3.86328 7.43522 6.05161 7.43522 8.73828C7.43522 11.4249 9.62356 13.6133 12.3102 13.6133C14.9969 13.6133 17.1852 11.4249 17.1852 8.73828C17.1852 6.05161 14.9969 3.86328 12.3102 3.86328ZM12.3102 11.4466C11.5919 11.4466 10.9031 11.1613 10.3951 10.6534C9.88723 10.1455 9.60189 9.45658 9.60189 8.73828C9.60189 8.01999 9.88723 7.33111 10.3951 6.8232C10.9031 6.31529 11.5919 6.02995 12.3102 6.02995C13.0285 6.02995 13.7174 6.31529 14.2253 6.8232C14.7332 7.33111 15.0186 8.01999 15.0186 8.73828C15.0186 9.45658 14.7332 10.1455 14.2253 10.6534C13.7174 11.1613 13.0285 11.4466 12.3102 11.4466Z" fill="#E7EEF8"/>
              </svg>
            </td>
            <td>
              <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                  <path d="M18.3148 3.52979C16.4298 3.52979 14.6206 4.40729 13.4398 5.79395C12.2589 4.40729 10.4498 3.52979 8.56478 3.52979C5.22811 3.52979 2.60645 6.15145 2.60645 9.48812C2.60645 13.5831 6.28978 16.9198 11.8689 21.9898L13.4398 23.409L15.0106 21.979C20.5898 16.9198 24.2731 13.5831 24.2731 9.48812C24.2731 6.15145 21.6514 3.52979 18.3148 3.52979ZM13.5481 20.3756L13.4398 20.484L13.3314 20.3756C8.17478 15.7065 4.77311 12.619 4.77311 9.48812C4.77311 7.32145 6.39811 5.69645 8.56478 5.69645C10.2331 5.69645 11.8581 6.76895 12.4323 8.25312H14.4581C15.0214 6.76895 16.6464 5.69645 18.3148 5.69645C20.4814 5.69645 22.1064 7.32145 22.1064 9.48812C22.1064 12.619 18.7048 15.7065 13.5481 20.3756Z" fill="#E7EEF8"/>
              </svg>
            </td>
            <td>
              <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                <path d="M4.89323 19.7796H20.0599L24.3932 24.113L24.3824 4.61296C24.3824 3.42129 23.4182 2.44629 22.2266 2.44629H4.89323C3.70156 2.44629 2.72656 3.42129 2.72656 4.61296V17.613C2.72656 18.8046 3.70156 19.7796 4.89323 19.7796ZM4.89323 4.61296H22.2266V18.8805L20.9591 17.613H4.89323V4.61296ZM7.0599 13.2796H20.0599V15.4463H7.0599V13.2796ZM7.0599 10.0296H20.0599V12.1963H7.0599V10.0296ZM7.0599 6.77962H20.0599V8.94629H7.0599V6.77962Z" fill="#E7EEF8"/>
              </svg>
            </td>
            <td>
              <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                <g clipPath="url(#clip0_37_1708)">
                  <path opacity="0.3" d="M20.1898 6.77995C20.7881 6.77995 21.2731 6.29492 21.2731 5.69661C21.2731 5.09831 20.7881 4.61328 20.1898 4.61328C19.5915 4.61328 19.1064 5.09831 19.1064 5.69661C19.1064 6.29492 19.5915 6.77995 20.1898 6.77995Z" fill="#E7EEF8"/>
                  <path opacity="0.3" d="M7.18978 14.363C7.78809 14.363 8.27311 13.8779 8.27311 13.2796C8.27311 12.6813 7.78809 12.1963 7.18978 12.1963C6.59147 12.1963 6.10645 12.6813 6.10645 13.2796C6.10645 13.8779 6.59147 14.363 7.18978 14.363Z" fill="#E7EEF8"/>
                  <path opacity="0.3" d="M20.1898 21.9679C20.7881 21.9679 21.2731 21.4829 21.2731 20.8846C21.2731 20.2863 20.7881 19.8013 20.1898 19.8013C19.5915 19.8013 19.1064 20.2863 19.1064 20.8846C19.1064 21.4829 19.5915 21.9679 20.1898 21.9679Z" fill="#E7EEF8"/>
                  <path d="M20.1899 17.6996C19.3666 17.6996 18.6299 18.0246 18.0666 18.5338L10.3424 14.038C10.3966 13.7888 10.4399 13.5396 10.4399 13.2796C10.4399 13.0196 10.3966 12.7705 10.3424 12.5213L17.9799 8.06879C18.5649 8.61046 19.3341 8.94629 20.1899 8.94629C21.9883 8.94629 23.4399 7.49462 23.4399 5.69629C23.4399 3.89796 21.9883 2.44629 20.1899 2.44629C18.3916 2.44629 16.9399 3.89796 16.9399 5.69629C16.9399 5.95629 16.9833 6.20546 17.0374 6.45462L9.39994 10.9071C8.81494 10.3655 8.04577 10.0296 7.18994 10.0296C5.39161 10.0296 3.93994 11.4813 3.93994 13.2796C3.93994 15.078 5.39161 16.5296 7.18994 16.5296C8.04577 16.5296 8.81494 16.1938 9.39994 15.6521L17.1133 20.1588C17.0591 20.3863 17.0266 20.6246 17.0266 20.863C17.0266 22.6071 18.4458 24.0263 20.1899 24.0263C21.9341 24.0263 23.3533 22.6071 23.3533 20.863C23.3533 19.1188 21.9341 17.6996 20.1899 17.6996ZM20.1899 4.61296C20.7858 4.61296 21.2733 5.10046 21.2733 5.69629C21.2733 6.29212 20.7858 6.77962 20.1899 6.77962C19.5941 6.77962 19.1066 6.29212 19.1066 5.69629C19.1066 5.10046 19.5941 4.61296 20.1899 4.61296ZM7.18994 14.363C6.59411 14.363 6.10661 13.8755 6.10661 13.2796C6.10661 12.6838 6.59411 12.1963 7.18994 12.1963C7.78577 12.1963 8.27327 12.6838 8.27327 13.2796C8.27327 13.8755 7.78577 14.363 7.18994 14.363ZM20.1899 21.968C19.5941 21.968 19.1066 21.4805 19.1066 20.8846C19.1066 20.2888 19.5941 19.8013 20.1899 19.8013C20.7858 19.8013 21.2733 20.2888 21.2733 20.8846C21.2733 21.4805 20.7858 21.968 20.1899 21.968Z" fill="#E7EEF8"/>
                </g>
              </svg>
            </td>
          </tr>
          <tr>
            <td>{formatNumber(video.nb_view)}</td>
            <td>{formatNumber(video.nb_like)}</td>
            <td>{formatNumber(video.nb_comm)}</td>
            <td>{formatNumber(video.nb_share)}</td>
          </tr>
        </tbody></table>
        <div className='video-card-footer'>
          {video.url ?
            video.url !== '#' ?
              <a href={video.url} target='_blank' rel="noreferrer">{t('View Video')}</a>
              :
              <a href={video.url} >{t('View Video')}</a>
          :
            <a href={video.tiktok_url} target='_blank' rel="noreferrer">{t('View Video')}</a>
          }
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
