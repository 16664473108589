import React, {useContext, useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { AuthContext } from '../AuthProvider';

const Filters = ({ selectedNiche, setSelectedNiche, filters, setFilters, selectedLang, setSelectedLang, selectedCountry, setSelectedCountry, selectedTimerange, setSelectedTimerange }) => {

  const [menuOpen, setMenuOpen] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const MAX = 1000;
  
  const backendUrl = process.env.REACT_APP_BACK_API_URL;
  
  const { rank } = useContext(AuthContext);

  const { t } = useTranslation();

  const languages = [
    {'all': 'All'}, {'en': 'English'}, {'fr': 'French'},
    {'es': 'Spanish'}, {'de': 'German'}, {'it': 'Italian'}, {'pt': 'Portuguese'}
  ];

  const countries = [
    {'all': 'All_2'}, {'US': 'United States'}, {'GB': 'United Kingdom'}, {'FR': 'France'},
    {'DE': 'Germany'}, {'IT': 'Italy'}, {'ES': 'Spain'}, {'PT': 'Portugal'}, {'BR': 'Brasil'}
  ];

  const timeranges = [{'0': 'All'}, {'7': '7 days'}, {'14': '14 days'}, {'30': '1 month'}, {'60': '2 months'}, {'365': '1 year'}, {'730': '2 years'}];

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target && !document.querySelector(".filterMenu").contains(event.target) && !document.querySelector(".filterRight button").contains(event.target)) {
        setMenuOpen(false);
      }
    };

    if (menuOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [menuOpen]); 

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios.get(`${backendUrl}/get_keywords`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        setKeywords(response.data.keywords);
      })
      .catch(error => console.error('Erreur lors de la récupération des mots-clés :', error));
    }
    else {
      setKeywords([]);
    }
  }, [rank, selectedLang]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleInputFilterChange = (filter, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filter]: parseInt(value, 10)
    }));
  };

  const handleSelectNicheChange = (e) => {
    setSelectedNiche(e.target.value);
  };

  const handleSelectLangChange = (e) => {
    setSelectedLang(e.target.value);
  };

  const handleSelectCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleSelectTimerangeChange = (e) => {
    setSelectedTimerange(e.target.value);
  };

  const formatValue = (value) => {
    if (value === '10') {
      return '1M';
    }
    else if (value !== 0 && value !== '0') {
      return `${value}k`;
    }
    return value.toString();
  };

  const calculateTrackBackground = (value) => {
    const percentage = (value / MAX) * 100;
    return `linear-gradient(to right, #E30E0E ${percentage}%, #ccc ${percentage}%)`;
  };

  const calculateTrackBackgroundSimple = (value) => {
    const percentage = value * 100;
    return `linear-gradient(to right, #E30E0E ${percentage}%, #ccc ${percentage}%)`;
  };

  const capitalizeFirstLetter = (string) => {
    return t(string.charAt(0).toUpperCase() + string.slice(1));
  };

  return (
      <div className="filter">
          <div className='filterRight'>
            <button onClick={toggleMenu}>
              <img src="/filter_red.svg" alt="" /> {t('Filters')}
            </button>
            {menuOpen && (
              <div className="filterMenu">
                <div className='filterNiche'>
                  <div className='filterNicheTitle'>{t('Niche')}</div>
                  <select onChange={handleSelectNicheChange} value={selectedNiche}>
                    <option key='0' value=''>-</option>
                    {keywords && Array.isArray(keywords) && keywords.map((keyword, index) => (
                      <option key={index} value={keyword}>{capitalizeFirstLetter(keyword)}</option>
                    ))}
                  </select>
                </div>
                <div className='filterCountry'>
                  <div className='filterCountryTitle'>{t('Country')}</div>
                  <select onChange={handleSelectCountryChange} value={selectedCountry}>
                    {countries.map((countryObject, index) => {
                      const countryKey = Object.keys(countryObject)[0];
                      const countryValue = countryObject[countryKey];
                      return (
                        <option key={index} value={countryKey}>{t(countryValue)}</option>
                      );
                    })}
                  </select>
                </div>
                <div className='filterLang'>
                  <div className='filterLangTitle'>{t('Language')}</div>
                  <select onChange={handleSelectLangChange} value={selectedLang}>
                    {languages.map((langObject, index) => {
                      const langKey = Object.keys(langObject)[0];
                      const langValue = langObject[langKey];
                      return (
                        <option key={index} value={langKey}>{t(langValue)}</option>
                      );
                    })}
                  </select>
                </div>
                <div className='filterTimerange'>
                  <div className='filterTimerangeTitle'>{t('Time range')}</div>
                  <select onChange={handleSelectTimerangeChange} value={selectedTimerange}>
                    {timeranges.map((timerangeObject, index) => {
                        const timerangeKey = Object.keys(timerangeObject)[0];
                        const timerangeValue = timerangeObject[timerangeKey];
                        return (
                          <option key={index} value={timerangeKey}>{t(timerangeValue)}</option>
                        );
                      })
                    }
                  </select>
                </div>
                {
                  rank >= 3 && 
                    <div className='filterDuration'>
                      <div className='filterDurationTitle'>{t('+1 min')} : </div>
                      <input
                        type="range"
                        min="0"
                        max="1"
                        onChange={(e) => handleInputFilterChange('duration', e.target.value)}
                        value={filters.duration}
                        style={{ background: calculateTrackBackgroundSimple(filters.duration) }}
                      />
                    </div>
                }
                {
                  rank >= 1 && 
                    <div className='filterViews'>
                      <div className='filterViewsTitle'>{t('Views')} : {formatValue(filters.views)}</div>
                      <div className='filterViewsRange'><span>0</span><span>1M+</span></div>
                      <input
                        type="range"
                        min="0"
                        max={MAX}
                        onChange={(e) => handleInputFilterChange('views', e.target.value)}
                        value={filters.views}
                        style={{ background: calculateTrackBackground(filters.views) }}
                      />
                    </div>
                }
                {
                  rank >= 3 &&
                    <div className='filterLikes'>
                      <div className='filterLikesTitle'>{t('Likes')} : {formatValue(filters.likes)}</div>
                      <div className='filterLikesRange'><span>0</span><span>1M+</span></div>
                      <input
                        type="range"
                        min="0"
                        max={MAX}
                        onChange={(e) => handleInputFilterChange('likes', e.target.value)}
                        value={filters.likes}
                        style={{ background: calculateTrackBackground(filters.likes) }}
                      />
                    </div>
                }
                {
                  rank >= 10 &&
                    <div className='filterComments'>
                      <div className='filterCommentsTitle'>{t('Comments')} : {formatValue(filters.comments)}</div>
                      <div className='filterCommentsRange'><span>0</span><span>1M+</span></div>
                      <input
                        type="range"
                        min="0"
                        max={MAX}
                        onChange={(e) => handleInputFilterChange('comments', e.target.value)}
                        value={filters.comments}
                        style={{ background: calculateTrackBackground(filters.comments) }}
                      />
                    </div>
                }
                {/*
                  rank >= 10 &&
                    <div className='filterShares'>
                      <div className='filterSharesTitle'>{t('Shares')} : {formatValue(filters.shares)}</div>
                      <div className='filterSharesRange'><span>0</span><span>1M+</span></div>
                      <input
                        type="range"
                        min="0"
                        max={MAX}
                        onChange={(e) => handleInputFilterChange('shares', e.target.value)}
                        value={filters.shares}
                        style={{ background: calculateTrackBackground(filters.shares) }}
                      />
                      </div>
                  */}
                {/*
                  rank >= 10 &&
                    <div className='filterBookmarks'>
                      <div className='filterBookmarksTitle'>{t('Bookmarks')} : {formatValue(filters.bookmarks)}</div>
                      <div className='filterBookmarksRange'><span>0</span><span>1M+</span></div>
                      <input
                        type="range"
                        min="0"
                        max={MAX}
                        onChange={(e) => handleInputFilterChange('bookmarks', e.target.value)}
                        value={filters.bookmarks}
                        style={{ background: calculateTrackBackground(filters.bookmarks) }}
                      />
                      </div>
                  */}
              </div>
            )}
          </div>
      </div>
  );
};

export default Filters;
