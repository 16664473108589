import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/Admin.css';
import { AuthContext } from '../AuthProvider';

const Admin = () => {

  const [nbUsers, setNbUsers] = useState(0);
  const [nbUsersFree, setNbUsersFree] = useState(0);
  const [nbUsersStandart, setNbUsersStandart] = useState(0);
  const [nbUsersAdvanced, setNbUsersAdvanced] = useState(0);
  const [nbUsersExpert, setNbUsersExpert] = useState(0);
  const [usersInfo, setUsersInfo] = useState([]);

  const backendUrl = process.env.REACT_APP_BACK_API_URL;

  const { rank } = useContext(AuthContext);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const getInfosAdmin = async () => {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${backendUrl}/admin`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    setNbUsers(response.data.nbUsers);
    setNbUsersFree(response.data.nbUsersFree);
    setNbUsersStandart(response.data.nbUsersStandart);
    setNbUsersAdvanced(response.data.nbUsersAdvanced);
    setNbUsersExpert(response.data.nbUsersExpert);
    setUsersInfo(response.data.usersInfo);
  }

  useEffect(() => {
    if (rank < 99) {
      navigate('/');
    }
    else {
      getInfosAdmin();
    }
  }, []);

  const updateUserAccess = async (user_id, newAccess) => {
    const token = localStorage.getItem('token');
    try {
      await axios.post(`${backendUrl}/update_user_access`, {
        user_id: user_id,
        new_access: newAccess
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      getInfosAdmin();
    } catch (error) {
      console.error('Error updating user access', error);
    }
  };

  return (
    <div className="admin">
      <h2>{t('Administration')}</h2>
      <h3>{t("Here you can view what's happening on the backend.")}</h3>
      <div className='admin_text'>
        {t('Number of active paying users')}: {nbUsers}
      </div>
      <div className='acmin_tex'>
        {t('Monthly earnings')}: ${(nbUsersStandart * 19.99 + nbUsersAdvanced * 29.99 + nbUsersExpert * 49.99).toFixed(2)}
      </div>
      <div className='admin_text'>
        {t('Number of users by plan')}
        <ul>
          <li>{t('Free')}: {nbUsersFree}</li>
          <li>{t('Standart')}: {nbUsersStandart}</li>
          <li>{t('Advanced')}: {nbUsersAdvanced}</li>
          <li>{t('Expert')}: {nbUsersExpert}</li>
        </ul>
      </div>
      <div>
        <h3>Liste des utilisateurs</h3>
        <table className='admin_table'>
          <thead>
            <tr>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {usersInfo.map((user, index) => (
              <tr key={index}>
                <td>{user.user_email}</td>
                <td>
                  {/*<button disabled={user.user_access === 1} onClick={() => updateUserAccess(user.user_id, 1)}>Plan Gratuit</button>*/}
                  <button disabled={user.user_access === 3} onClick={() => updateUserAccess(user.user_id, 3)}>Plan Standard</button>
                  <button disabled={user.user_access === 5} onClick={() => updateUserAccess(user.user_id, 5)}>Plan Pro</button>
                  <button disabled={user.user_access === 10} onClick={() => updateUserAccess(user.user_id, 10)}>Plan Expert</button>
                  <button disabled={user.user_access === 0} onClick={() => updateUserAccess(user.user_id, 0)}>Retirer Plan</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Admin;
