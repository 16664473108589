import React from'react';
import { useTranslation } from 'react-i18next';
import '../styles/Loginbar.css';

const Loginbar = ({ setShowRegister, setShowOverlay }) => {

    const { t } = useTranslation();  
  
    const handleLoginLogout = () => {
        setShowRegister(true);
        setShowOverlay(true);
    };

    return (
        <div className="loginbar">
            <div className='loginbar_emote'>
                <img src="/smiley.png" alt="" />
            </div>
            <div className='loginbar_text'>{t('To see more, you need to login')}</div>
            <div className='loginbar_button'>
                <button className="loginButton"  onClick={() => handleLoginLogout()}>
                    <img src="/login.svg" alt="login" className="loginIcon"/>{t('Login')}
                </button>
            </div>
        </div>
    );
};

export default Loginbar;