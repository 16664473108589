import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import VideoCard from './VideoCard';
import '../styles/AnalyseAccount.css';

const AnalyseAccount = () => {

  const [creator, setCreator] = useState({});
  const [creatorRegion, setCreatorRegion] = useState('');
  const [creatorLanguage, setCreatorLanguage] = useState('');
  const [creatorTag, setCreatorTag] = useState('');
  const [creatorUrl, setCreatorUrl] = useState('');
  const [creatorMonthlyViews, setCreatorMonthlyViews] = useState(0);
  const [creatorMonthlyRevenue, setCreatorMonthlyRevenue] = useState(0);
  const [creatorNbVideosThisMonth, setCreatorNbVideosThisMonth] = useState(0);
  const [creatorTotalViews, setCreatorTotalViews] = useState(0);
  const [creatorLastThreeVideos, setCreatorLastThreeVideos] = useState([]);

  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const creatorTag = location.pathname.split('/')[2];
      try {
        const route = `${process.env.REACT_APP_BACK_API_URL}/get_creator_by_tag/${creatorTag}`;
        const token = localStorage.getItem('token');
        const returnData = await axios.get(route, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (returnData.data) {
          setCreator(returnData.data);
        }
      }
      catch (error) {
        navigate('/');
        console.error('Error adding favorite: ', error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (creator.cover) {
      getInfosAccount();
      getInfosTiktoks();
    }
  }, [creator]);

  const getCover = () => {
    return `${process.env.REACT_APP_BACK_API_URL}/tiktok_cover/${creator.cover}`;
  };

  const getInfosAccount = () => {
    const url = creator['tiktoks'][0]['tiktok_url'];
    setCreatorUrl(url.substring(0, url.indexOf('/video/')));
    setCreatorTag(url.substring(url.indexOf('@') + 1, url.indexOf('/video/')));
    if (creator['region']) {
      setCreatorRegion(creator['region']);
    }
    else {
      setCreatorRegion(creator['tiktoks'][0]['region']);
    }
    if (creator['lang']) {
      setCreatorLanguage(creator['lang']);
    }
    else {
      setCreatorLanguage(creator['tiktoks'][0]['lang']);
    }
  };

  const getInfosTiktoks = () => {
    let creatorMonthlyViews = 0;
    let creatorMonthlyRevenue = 0;
    let totalViews = 0;
    let nbVideosThisMonth = 0;
    let lastThreeVideos = [];
    for (let i = 0; i < creator.tiktoks.length; i++) {
      const publishDate = creator.tiktoks[i].publish_tiktok;
      const now = new Date().getTime() / 1000;
      const diff = Math.abs(now - publishDate);
      const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
      if (diffDays <= 30) {
        creatorMonthlyViews += creator.tiktoks[i].nb_view;
        nbVideosThisMonth += 1;
        if (creator.tiktoks[i].duration > 60000) {
          creatorMonthlyRevenue += creator.tiktoks[i].nb_view / 1000 * 0.45;
        }
      }
      totalViews += creator.tiktoks[i].nb_view;
    }
    if (creator.tiktoks && creator.tiktoks.length) {
      const sortedTiktoks = [...creator.tiktoks].sort((a, b) => b.publish_tiktok - a.publish_tiktok);
      
      lastThreeVideos = sortedTiktoks.slice(0, 3);
    }
    setCreatorMonthlyViews(creatorMonthlyViews);
    setCreatorMonthlyRevenue(creatorMonthlyRevenue);
    setCreatorNbVideosThisMonth(nbVideosThisMonth);
    setCreatorTotalViews(totalViews);
    setCreatorLastThreeVideos(lastThreeVideos);
  };

  const formatNumber = (num) => {
    if (num >= 1000000000) {
      return (num / 1000000).toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + 'M';
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'k';
    } else {
      return num.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1});
    }
  }

  const simpleFormatNumber = (num, nbZero = 2) => {
    if (num >= 1000) {
      return num.toFixed(nbZero).toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1});
    } else {
      return num.toFixed(nbZero);
    }
  }

  const getRegionToCopy = () => {
    if (creatorRegion === 'US') {
      return 'FR';
    }
    else if (creatorRegion === 'FR') {
      return 'DE';
    }
    else if (creatorRegion === 'DE') {
      return 'IT';
    }
    else if (creatorRegion === 'IT') {
      return 'ES';
    }
    else if (creatorRegion === 'ES') {
      return 'PT';
    }
    else if (creatorRegion === 'PT') {
      return 'IT';
    }
    else {
      return 'US';
    }
  }

  const getGrowing = () => {
    if (creatorMonthlyViews > 3000000) {
      return t('Fast');
    }
    else if (creatorMonthlyViews > 100000) {
      return t('Normal');
    }
    else {
      return t('Slow');
    }
  }

  const canCopy = () => {
    if (creatorMonthlyViews > 300000) {
      return t('Yes');
    }
    else {
      return t('No');
    }
  } 

  const goBack = () => {
    window.history.back();
  };

  const goToTiktok = () => {
    window.open(creatorUrl, '_blank')
  };

  return (
    <div className="analyse_account">
      <h2>{t('Creator Analyse')}</h2>
      <div className='account_blocs'>
        <div className='account_general_bloc big_bloc'>
          <div className='account_details'>
            <div className='account_picture'>
              <img src={getCover()} alt="" />
            </div>
            <div className='account_details_text'>
              <div className='account_name'>
                <h3>{creator.nickname}</h3>
              </div>
              <div className='account_tag'>
                <h4>{creatorTag}</h4>
              </div>
            </div>
          </div>
          <div className='account_links'>
            <div className='account_back_link'>
              <button className="secondary" onClick={() => goBack()}>
                <img src='/arrow-left.svg' alt={t('Back')} />
              </button>
            </div>
            <div className='account_link'>
              <button onClick={() => goToTiktok}>{t('Go to the account')}</button>
            </div>
          </div>
        </div>
        <div className='account_general_bloc'>
          <h4>{t('Daily Sales Revenus')}</h4>
          <div className='account_bloc_txt'>${simpleFormatNumber((creatorMonthlyRevenue/30))}</div>
        </div>
        <div className='account_general_bloc'>
          <h4>{t('Last Month Sales Revenus')}</h4>
          <div className='account_bloc_txt'>${simpleFormatNumber(creatorMonthlyRevenue)}</div>
        </div>
        <div className='account_general_bloc'>
          <h4>{t('Growing')}</h4>
          <div className='account_bloc_txt'>{getGrowing()}</div>
        </div>
        <div className='account_general_bloc'>
          <h4>{t('Language')}</h4>
          <div className='account_bloc_txt'>{t(creatorLanguage + '_langue')}</div>
        </div>
        <div className='account_general_bloc'>
          <h4>{t('Last Month Views')}</h4>
          <div className='account_bloc_txt'>{formatNumber(creatorMonthlyViews)}</div>
        </div>
        <div className='account_general_bloc'>
          <h4>{t('Last Month Videos')}</h4>
          <div className='account_bloc_txt'>{simpleFormatNumber(creatorNbVideosThisMonth, 0)}</div>
        </div>
        {/*<div className='account_general_bloc'>
          <h4>{t('Total Views')}</h4>
          <div className='account_bloc_txt'>{formatNumber(creatorTotalViews)}</div>
        </div>*/}
        {creator.niche &&
          <div className='account_general_bloc'>
            <h4>{t('Niche')}</h4>
            <div className='account_bloc_txt'>{t('Football')}</div>
          </div>
        }
        <div className='account_general_bloc big_bloc'>
          <h4>{t('Region')}</h4>
          <div className='account_bloc_txt big_txt'>{t(creatorRegion + '_long')}</div>
        </div>
        <div className='account_general_bloc'>
          <h4>{t('Can copy?')}</h4>
          <div className='account_bloc_txt'>{canCopy()}</div>
        </div>
        <div className='account_general_bloc big_bloc'>
          <h4>{t('Region to copy')}</h4>
          <div className='account_bloc_txt big_txt'>{t(getRegionToCopy() + '_long')}</div>
        </div>
      </div>
      <h2>{t('Last 3 videos')}</h2>
      <div className='last_videos'>
        {
          creatorLastThreeVideos.map((video) => (
            <VideoCard key={video.id} video={video} />
          ))
        }
      </div>
    </div>
  );
};

export default AnalyseAccount;
