import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../styles/Sidebar.css';
import { AuthContext } from '../AuthProvider';

const Sidebar = ({getActiveClass}) => {

  const { pseudo, rank } = useContext(AuthContext);

  const { t } = useTranslation();

  return (
    <div className="aside">
      <div className="div-aside-head">
        <img className="frame" alt="Frame" src="/logo.png" />
        <div className="title-wrapper">SpyTik</div>
      </div>
      <div className="div-aside-body">
        <div className="item-tiktok">{t('Home')}</div>
        <Link to="/" className={getActiveClass("/")}>
          <img className="SVG" alt="Svg" src="/elements.svg" />
          <div className="text-wrapper">{t('Discover')}</div>
        </Link>
        <Link to="/top_creator" className={getActiveClass("/top_creator")}>
          <img className="SVG" alt="Svg" src="/fire.svg" />
          <div className="text-wrapper">{t('Top Creators')}</div>
        </Link>
        <Link to="/top_videos" className={getActiveClass("/top_videos")}>
          <img className="SVG" alt="Svg" src="/top-videos.svg" />
          <div className="text-wrapper">{t('Top Videos')}</div>
        </Link>
        <div className="item-tiktok">{t('Search and Tracking')}</div>
        <Link to="/analyse" className={getActiveClass("/analyse")}>
            <img className="SVG" alt="Svg" src="/eye.svg" />
            <div className="text-wrapper">{t('Creator Analyse')}</div>
        </Link>
        <Link to="/download" className={getActiveClass("/download")}>
            <img className="SVG" alt="Svg" src="/download.svg" />
            <div className="text-wrapper">{t('Download video')}</div>
        </Link>
        <Link to="/favorites" className={getActiveClass("/favorites")}>
          <img className="SVG" alt="Svg" src="/star.svg" />
          <div className="text-wrapper">{t('Favorites')}</div>
        </Link>
        <div className="item-tiktok">SpyTik</div>
        <Link to="/subscription" className={getActiveClass("/subscription")}>
          <img className="SVG" alt="Svg" src="/signet.svg" />
          <div className="text-wrapper">{t('Subscription')}</div>
        </Link>
        <Link to="/affiliate" className={getActiveClass("/affiliate")}>
          <img className="SVG" alt="Svg" src="/affiliate.svg" />
          <div className="text-wrapper">{t('Affiliate')}</div>
        </Link>
        <Link to="/terms" className={getActiveClass("/terms")}>
            <img className="SVG" alt="Svg" src="t-c.svg" />
            <div className="text-wrapper">{t('Terms and Conditions')}</div>
        </Link>
        {localStorage.getItem('token') &&
          <Link to="/profile" className={getActiveClass("/profile")}>
            <img className="SVG" alt="Svg" src="user.svg" />
            <div className="text-wrapper">{t('My account')}</div>
          </Link>
        }
        {rank >= 99 &&
          <Link to="/admin" className={getActiveClass("/admin")}>
            <img className="SVG" alt="" src="/user.svg" />
            <div className="text-wrapper">{t('Admin')}</div>
          </Link>
        }
      </div>
      <div className="div-aside-space"></div>
      <div className="div-aside-foot">
        <div className='user_profile_div'>
          <div className="presentation-user">
            <div className="user" ><img src="/profile_picture.png" alt="" /></div>
            <div className="text-wrapper">{pseudo}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
