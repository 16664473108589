import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import '../styles/Download.css';

const Download = () => {
  const [url, setUrl] = useState('');
  const [downloading, setDownloading] = useState(false);
  const [downloadReady, setDownloadReady] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { t } = useTranslation();
  
  const backendUrl = process.env.REACT_APP_BACK_API_URL;

  const handleDownload = async () => {
    setDownloading(true);
    setDownloadReady(false);
    setDownloadLink('');
    setErrorMessage('');
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${backendUrl}/download`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        params: {
          url: url
        }
      });
      if (response.data.result) {
        setDownloadLink(response.data.result);
        setDownloadReady(true);
        setDownloading(false);
      }
    } catch (error) {
      if (error.response.data.error) {
        setErrorMessage(error.response.data.error);
        setDownloading(false);
      }
      console.error('Error fetching search results: ', error);
      setDownloading(false);
    }
  };

  return (
    <div className="download">
      <h2>{t('Download')}</h2>
      <h3>{t('Download the TikTok video of your choice using the link.')}</h3>
      <div className='download-link'>
        <input type="text" placeholder={t('Paste the link to initiate the download.')} onChange={(e) => setUrl(e.target.value)} />
        {downloading ?
          <button disabled>{t('Downloading...')}</button>
          :
          <button onClick={() => handleDownload()}>{t('Download')}</button>
        }
        {downloadReady && 
          <a href={downloadLink} download="spytik_video.mp4" target="_blank" rel="noreferrer">
            {t('Download here')}
          </a>
        }
        {errorMessage &&
          <div className='error_message'>{errorMessage}</div>
        }
      </div>
    </div>
  );
};

export default Download;
